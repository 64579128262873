import "../../../pages/.shared.ts";
import "../../../assets/styles/views/login/main.scss";
import { html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '@material/mwc-icon';
import '@material/mwc-button';
import '@material/mwc-textfield';
import { MobxLitElement } from "@adobe/lit-mobx";
import { CONSTANTS } from '@@addons/constants';
import { LoginStore } from "@@addons/database/dixie/login";
import { POST_ClientLogin } from "@@addons/network/login/client";
import { togglePswdVisibility } from "@@addons/functions/toggle_pswd_visibility";

let loginDb = new LoginStore();
loginDb.loggedIn();

@customElement("akwaaba-forms-login")
export class AkwaabaFormsLogin extends MobxLitElement {
  constructor() { super(); }

  //  @query(identifier)
  //  private _identifier?: any;

  //  @queryAll(identifier)
  //  private _identifier?: any;

  @property({ type: String })
  private leftColBgStyle?: string = `${CONSTANTS.URLS.FILE_BUCKET_BASE_URL}files/images/akwaaba-forms-login.jpg'`;

  connectedCallback() {
    super.connectedCallback();
    // console.log({
    //   "akwaaba-forms-login": "INIT",
    // });
  }

  disconnectedCallback() { }
  static styles = [
    css`
    :host { display: block; }
    `
  ];

  render() {
    this
    return html`
    <div class="login">
      <header class="header">
        <img class="img" src="${CONSTANTS.URLS.FILE_BUCKET_BASE_URL}files/images/logo-inverse.png" alt="logo" />
      </header>
      <main class="main">
        <div class="box">
          <div class="slight-right"></div>
          <div class="slight-left"></div>
          <div class="form-container">
            <div class="form-row">
              <div class="form-left-col">
                <div class="bg-img" style="background-image:url(${CONSTANTS.URLS.FILE_BUCKET_BASE_URL}files/images/akwaaba-forms-login.jpg)" >
                </div>
              </div>
              <div class="form-right-col">
                <label for="" class="label">
                  <mwc-icon class="icon">lock</mwc-icon>
                  <h1 class="h1">Sign In</h1>
                  <h3 class="h3">Sign in to your account!</h3>
                </label>
                <form method="post" action="#" action="#" class="form" make-general-posts="client-login">
                  <div class="form-input-container">
                    <mwc-textfield name="email_phone" label="E-mail/ Phone Number" icon="person" outlined type="text"
                      required>
                    </mwc-textfield>
                  </div>
                  <div class="form-input-container">
                    <mwc-textfield name="password" label="Password" icon="lock" outlined type="password" required>
                    </mwc-textfield>
                  </div>
    
                  <div class="form-input-container flex">
                    <label for="remember_me" class="inline-flex items-center w-full cursor-pointer">
                      <input id="remember_me" type="checkbox"
                        class="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        name="remember">
                      <span class="ml-2 text-sm text-gray-600">
                        Remember Me
                      </span>
                    </label>
    
                    <div class="w-full text-right">
                      <a class="underline text-sm text-gray-600 hover:text-gray-900" 
                        href="${CONSTANTS.URLS.HOME_PAGE_BASE_URL}forgot-password">
                        forgot password?
                      </a>
                    </div>
                  </div>
    
                  <div class="form-input-container">
                    <mwc-button label="Login" raised class="button" @click="${this.submitForm}">
                    </mwc-button>
                  </div>
    
                  <div class="flex form-input-container items-center text-center">
                    <hr class="border-gray-300 border-1 w-full rounded-md">
                    <label class="block font-medium text-sm text-gray-600 w-full">
                      Lets go digital...
                    </label>
                    <hr class="border-gray-300 border-1 w-full rounded-md">
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    `;
  }

  firstUpdated() {
    togglePswdVisibility();
    togglePswdVisibility("mwc-textfield");
  }

  async submitForm(e: PointerEvent) {
    e.preventDefault();
    // console.log({ e });
    // await CONSTANTS.NETWORK_CALLS.clientLogin({});
    await POST_ClientLogin({});
  }

  createRenderRoot() {
    return this;
  }
}
